@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:300,400,700');

@font-face {
	font-family: Heavitas;
	src: url(../fonts/heavitas.woff) format("woff");
	font-weight: 400;
	font-style: normal
}
@font-face {
	font-family: Knight Brush;
	src: url(../fonts/knight-brush.woff) format("woff");
	font-weight: 400;
	font-style: normal
}

body {
   @include font-setting($primary-font, normal, 400);
    color: $text-color;
	text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
	font-size: 1rem;
}

.show-for-sr {
	border: solid 1px red;
}

h1, h2, h3, h4, h5, h6 {
	@include font-setting($header-font, normal, 400);
	color: $text-color;
	font-weight: 400;
	text-transform: uppercase;
}

h1 {
	font-size: rem(38);
}

h2 {
	font-size: rem(32);
	color: $white;
	margin-bottom: rem(30);
	span {
		color: #b11f24;
	}
} 

h3 {
	font-size: rem(26);
	margin-bottom: rem(30);
}

h4 {
	font-size: rem(20);
}

h5 {
	font-size: rem(18);
}

h6 {
	font-size: rem(16);
}

//Links

a, a:visited {
	color:$color-link;
	text-decoration: none;
	transition: all 0.3s ease;
	&:hover, &:active {
		color:$color-link-hover;
		text-decoration:none;
		outline: 0;
		text-decoration: underline;
	}
	// &:focus {
	// 	outline: dotted 2px red;
	// }
}

p.dropcap:first-child:first-letter {
	color: #903;
	float: left;
	font-family: Georgia;
	font-size: rem(75);
	line-height: rem(60);
	padding: rem(4) rem(8) rem(3) 0;
}

button  {
	outline: 0;
}

hr {
	margin: rem(40) 0;
    border: 0;
    border-top: 1px dotted $dk-grey;
	&.white {
		border-top: 1px dotted $white;
	}
}

.screen-reader-text {
    left: -9999em;
    position: absolute;
    top: -9999em;
}

.skiplink-text {
	color: $white;
	&:hover {
		color: $white;
	}
}