
// Basic Contain
@mixin basic-contain {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
}



// Display Block
@mixin block {
    display: block;
}

// Font Setting
@mixin font-setting($family, $style, $weight) {
    font-family: $family;
    font-style: $style;
    font-weight: $weight;
}
// Typeset
@mixin typeset($size) {
    font-size: px($size);
    //line-height: leading($leading, $size);
}

// Reset List
@mixin reset-list {
    list-style: none;
    padding-left: 0;
}

// Truncate Text
@mixin truncate-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

// Truncate Text Scroll
@mixin truncate-text-scroll {
    display: block;
    overflow: scroll;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

@mixin media($conditions...) {
  @for $i from 1 through length($conditions) {
    $conditions: set-nth($conditions, $i, parse-expression(nth($conditions, $i)));
  }

  $branches: get-query-branches($conditions);
  $query: '';

  @each $branch in $branches {
    @if (str-length($query) != 0) {
      $query: $query + ', ';
    }

    $query: $query + $branch;
  }

  @media #{$query} {
    @content;
  }
}