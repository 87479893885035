// PROJECT COLORS

// Brand Colors
$main-color-one: #002D6A;
$main-color-two: #d31145;;
$main-color-three: green;
$main-color-four: green;
$main-color-five: blue;
$hilite-color: yellow;
$text-color: #141414;

// Link & button colors
$color-link: $main-color-one;
$color-link-hover: $main-color-one;
$color-button: $main-color-two;
$color-button-hover: darken($main-color-two, 20%);
$color-button-alt: $main-color-two;
$color-button-alt-hover: lighten($main-color-two, 20%);

// Sidebar nav colors
$sidebar-nav-color-one: $main-color-one;
$sidebar-nav-color-two: darken($main-color-one, 20%);
$sidebar-nav-color-three: darken($main-color-one, 30%);

// Alert colors
$color-alert: #003c71;
$color-alert-emergency: #ea002b;


// Greyscale colors
$white: #fff;
$black: #000;
$rgb-white: "255,255,255" !default;
$rgb-black: "0,0,0" !default;
$xlt-grey: #f5f0ea;
$lt-grey: #545353;
$grey: #232323;
$dk-grey: #1f1f1f;
$xdk-grey: #1c1c1c;

/* Fonts */
$primary-font: 'Open Sans', sans-serif !default;
$header-font: 'Heavitas', sans-serif;
// $header-font:  'brandon-grotesque',sans-serif !default;
$nav-font: $header-font;
$condensed-font: 'Open Sans Condensed', sans-serif !default;
$serif-font: 'Open Sans', sans-serif!default;
$sans-serif-font: 'Open Sans', sans-serif !default;
$icon-font: 'Font Awesome 5 Pro';
$icon-font-brands: 'Font Awesome 5 Brands';
$icon-font-alt: 'Material Icons';

/* Font Sizes */
$font-xs: rem(12);
$font-sm: rem(14);
$font-lg: rem(18);
$font-xl: rem(20);


// Slick Slider Inputs
$slick-font-path: "./fonts/" !default;
$slick-font-family: "Font Awesome 5 Pro" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: $main-color-one !default;
$slick-dot-color: $main-color-one !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f053" !default;
$slick-next-character: "\f054" !default;
$slick-dot-character: "\f192" !default;
$slick-dot-size: 22px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;


$slick-foo: 1 !default;


// Transitions
$transition-time: .2s;
$transition-easing: ease-out;

// Margins
$margin-bottom: rem(40);

// Widths
$max-width: rem(1400);



// Standard Nav Colors
$nav-color-one: #334b94;
$nav-color-hover-one: rgba(0,0,0,.5);
$nav-color-two: $main-color-two;
$nav-color-three: purple;
$nav-color-four: green;

// Nav Colors
$primary-button-color:$white;
$primary-button-color-hover: #CCC;
$nav-primary: $main-color-one;
$nav-bg: $white;
$breadcrumb-color: #CCC;
$breadcrumb-bg: #ac1f27;

// Mega Menu Main Nav Colors
$mega-bg-one: darken($main-color-one, 15%);
$mega-bg-two: green;
$mega-link-bg-hover: rgba(0,0,0,.5);
$mega-link-color: rgba(255,255,255,.95);
$mega-link-color-hover: $white;
$mega-link-border-color: rgba(255,255,255,.75);
$mega-link-border-color-hover: $white;

// Mega Menu Mobile Nav Colors
$mega-mob-color-one: $main-color-one;
$mega-mob-color-two: darken($main-color-one, 5%);
$mega-mob-link-color-one: $white;
$mega-mob-border-color: $white;

// Top Nav Colors
$top-nav-color-one: $main-color-one;
$top-nav-border-color-one: $main-color-one;
$top-nav-border-color-one-hover: $white;
$top-nav-border-color-mob: $white;

// First Level Subnav Colors
$subnav-border-color: #777;
$subnav-color: #777;
$subnav-bg-color: $white;
$subnav-color-hover: $white;
$subnav-bg-color-hover: #777;

// Second Level Subnav Colors
$subnav-color2: $white;
$subnav-bg-color2: #424242;
$subnav-color-hover2: $white;
$subnav-bg-color-hover2: $white;

// Toggle Sub
$toggle-sub-color-one: $mega-mob-color-two;
$toggle-sub-height: 69px;
$toggle-sub-width: 68px;



