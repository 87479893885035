$white: $white;
$black: $black;
$cal-color-one: $main-color-one;
$cal-border-color: $main-color-one;
$cal-dayNum: $black;
$cal-otherMonthBg: $xlt-grey;
$cal-multiDayBg: lighten($main-color-one, 70%);
$cal-otherMonthDayNum: $lt-grey;
$cal-linkSize: rem(12) !important;
$cal-lineHeight: rem(12) !important;
$cal-linkColor: #c40505;
$cal-filterColor: $black;
$cal-filterColorHover: $black;
$cal-filterColorActive: $white;
$cal-filterBgColor: transparent;
$cal-filterBgColorHover: $xlt-grey;
$cal-filterBgColorActive: $main-color-one;
$cal-mainListLinkColor: #c40505;
$cal-border-radius: 20px;


@import 'calendar/_calendar_grid';
@import 'calendar/_calendar_filters';
@import 'calendar/_calendar_controls';
@import 'calendar/_calendar_layout';
@import 'calendar/_calendar_main_list';
@import 'calendar/_calendar_event_list';

html {
    box-sizing: border-box;
  }

*, ::after, ::before, div {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}


.input-group.date .input-group-addon {
    cursor: pointer
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}




.calendar-controls .btn:after,
.calendar-controls a.btn:after,
#calendar .btn:after,
#calendar a.btn:after,
.btn.view-more:after,
a.btn.view-more:after {
    content: none;
}

.calendar-controls .btn-sm,
.calendar-controls a.btn-sm,
#calendar .btn-sm,
#calendar a.btn-sm,
#calendar .btn-xs,
#calendar a.btn-xs {
    min-width: 0;
    font-size: .875rem;
    padding: .25rem .5rem;
}





