.cal-container {
    max-width: 75rem;
    margin: 0 auto;
    padding-right: .9375rem;
    padding-left: .9375rem;
}

.cal-grid {
    display: block;
    @include media(">=md") {
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin: 0;
        padding: 0;
        .calendar {
            display: block;
            @include media(">=md") { 
                width: 75%;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
                min-height: 0;
                min-width: 0;
            }
        }
        .filters {
            display: block;
            @include media(">=md") { 
                width: 25%;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
                min-height: 0;
                min-width: 0;
            }
        }
    } 
}

.calendar-controls {
    display: block;
    @include media(">=md") {
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin: 0;
        padding: 0;
    }
}