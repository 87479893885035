
ol.event-list {
    list-style: none;
    padding: 20px 20px 0 20px;
    margin: 0
}

ol.event-list li {
    border-bottom: #0079c2 dotted 1px;
    margin-top: 10px
}

ol.event-list li:last-child {
    border-bottom: none
}

ol.event-list li:first-child {
    margin-top: 0
}

ol.event-list li a {
    color: #002E5D;
    font-weight: 700;
    font-size: 18px
}

ol.event-list .small {
    display: none
}

ol.event-list .small .small {
    display: inline
}

ol.event-list .small .large {
    display: none
}

ol.event-list .small p {
    display: none
}
