// IMPORT THIRD PARTY CSS
///////////////////////////////////////////
@import '../third-party-css/fontawesome-all.min.css';


// CORE UTILITIES
///////////////////////////////////////////
@import 'utils/inputs';
@import 'utils/functions';
@import 'utils/mixins';
@import 'utils/variables';


// BASE
///////////////////////////////////////////
@import 'base/typography';


// CALENDAR
///////////////////////////////////////////
@import 'calendar/calendar';

